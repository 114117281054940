(function(){
	'use strict';
	angular.module('ctp.zeroHeight', [])
		.directive('zeroHeight', [ function () {
			return {
				scope: {},
				restrict: 'C',
				link: function (scope, element, attrs) {
					var height = element.height();
					scope.height = element.height();
					element.add( element.find("*") ).css("visibility", "hidden");
					function checkheight () {
						if (scope.height !== height) {
							element.closest('.zero-hider').css("margin-top", "-" + scope.height + "px");
							height = scope.height;
						}
					}
					element.bind('DOMNodeInserted', function(event){
						scope.height = element.height();
						checkheight();
					});
				}
			};
		}]);
})();